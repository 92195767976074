import { navigate } from "gatsby";
import React from "react";
import { Layout, SingleSwingUser } from "../components";
import { AgenciesSection as SwingUsersSection } from "../style";
import { useSelector } from "react-redux";

function SwingUser({ location = {} }) {
  if (location.state === null || undefined) {
    navigate("/swingusers");
    return;
  }
  const allUsers = useSelector(state => state.swing.swingusers)
  const userData = allUsers.find(user => user.id === location.state.data.id)
  

  return (
    <div>
      <Layout>
        <SwingUsersSection>
          <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
            <p
              onClick={() => {
                typeof history !== "undefined" && history.go(-1);
              }}
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                fontSize: "1.2rem",
                marginBottom: "1rem",
              }}
            >
              <b>Swing Users</b>
            </p>
            <p
              style={{
                fontSize: "1.2rem",
                marginBottom: ".9rem",
                marginLeft: "1rem",
                marginRight: "1rem",
              }}
            >
              <b> &#8827;</b>
            </p>
            <p
              style={{
                fontSize: "1.2rem",
                marginBottom: "1rem",
              }}
            >
              <b>User ID: {userData?.id || " "}</b>
            </p>
            <p
              style={{
                fontSize: "1.6rem",
                marginBottom: "1.2rem",
                marginLeft: "10rem",
                marginRight: "1rem",
              }}
            >
            
              {userData?.name || " "}
            </p>
          </div>
          <SingleSwingUser
            swingUserData={userData}
          />
        </SwingUsersSection>
      </Layout>
    </div>
  );
}

export default SwingUser;
